<template>
  <header class="l-header">
    <div class="l-header-inner">
      <div class="l-header-left">
        <NuxtLink to="/" active-class="active" class="c-hover">
          <img
            src="@/assets/img/logo.png"
            alt=""
            style="height: 25px"
            :class="{ animation: isAnimating }"
          />
        </NuxtLink>
      </div>

      <div class="l-header-right">
        <span class="l-header-icon">
          <img
            src="@/assets/svg/hamburger.svg"
            class="l-header-left-menu c-hover"
            @click="useSettingStore().toggleMenu()"
          />
          <!-- 引き換え可能時のアイコン -->
          <font-awesome-icon
            v-if="isNotsendIcon"
            :icon="['fas', 'circle-exclamation']"
          />
        </span>
      </div>

      <div
        v-if="isMenuOpen"
        class="menu-list-overlay"
        @click="isMenuOpen = !isMenuOpen"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
const isNotsendIcon = computed(() => useUserRewordStore().notsendCount > 0)
</script>

<script lang="ts">
/**
 * ヘッダー
 */
export default {
  name: 'GachaDetailHeaderLayout',
  data: () => ({
    // メニューを開くかどうか
    isMenuOpen: false,
    isMiniMenuOpen: false,
    isAnimating: false,
  }),
  computed: {
    isShowBack() {
      return (
        this.$route.name
        && (Boolean(String(this.$route.name).startsWith('help-'))
        || Boolean(String(this.$route.name).startsWith('profile-setting-')))
      )
    },
  },
  mounted() {
    setInterval(this.startAnimation, 30000) // 30秒ごとにアニメーションを開始
  },
  methods: {
    onClickToggleMenu() {
      this.$nextTick(() => {
        this.isMenuOpen = !this.isMenuOpen
      })
      return true
    },
    onClickToggleMiniMenu() {
      this.$nextTick(() => {
        this.isMiniMenuOpen = !this.isMiniMenuOpen
      })
      return true
    },
    async onClickLogout() {
      navigateTo('/logout')
      this.isMenuOpen = false
    },
    startAnimation() {
      this.isAnimating = true
      setTimeout(() => {
        this.isAnimating = false
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
.l-header {
  position: fixed;
  top: 0;
  z-index: $header-z-index;
  width: 100%;
  background-color: transparent;

  &-dummy {
    z-index: calc($header-z-index - 5);
    position: fixed;
    width: 100%;
    top: 0;
  }
  @include media-sp-tb {
    width: 100%;
  }

  @include media-sp {
    border: none;
  }

  &-icon {
    position: relative;
    svg {
      position: absolute !important;
      top: -3px !important;
      right: 3px !important;
      color: #fa2f2f;
      &.svg-inline--fa.fa-circle-exclamation {
        color: #fa2f2f;
        font-size: 21px;
        position: absolute;
        right: 0;
        top: 35%;
        transform: translateX(50%);
        background-color: #fff;
        border-radius: 100%;
      }
    }
  }

  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: $header-height;
    @include media-sp {
      height: $sp-header-height;
    }
  }

  &-left,
  &-right {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-left {
    padding-left: 20px;
    a {
      position: absolute;
      top: 10px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      background: #fff;
      padding: 18px 32px;
      border-radius: 100px;
      width: 328px;
      height: 64px;
      box-sizing: border-box;
      @include media-sp {
        display: none;
      }
      img {
        width: 264px;
        height: 30px !important;
        object-fit: contain;
      }
    }
    img.animation {
      animation: purupuru_anim 0.6s infinite alternate;
    }

    &-menu {
      width: 46px;
      cursor: pointer;
      vertical-align: bottom;

      @include hover() {
        opacity: 0.5;
      }

      @include media-pc() {
        display: none;
      }
    }

    &-title {
      width: 100%;
      font-weight: bold;
    }
  }

  &-right {
    @include media-pc {
      display: none;
    }
    padding-right: 20px;
  }
}

.menu-list-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: $menu-overlay-z-index;
}

@keyframes purupuru_anim {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(-5px, -0);
  }
  10% {
    transform: translate(5px, 0);
  }
  15% {
    transform: translate(-5px, -0);
  }
  20% {
    transform: translate(5px, 0);
  }
  25% {
    transform: translate(-5px, -0);
  }
  30% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
