<template>
  <div class="l-page">
    <div id="js-page-right" class="l-page-wrapper">
      <GachaDetailHeader />
      <div :class="{ menu: isMenu }" class="l-page-side">
        <SideMenu />
      </div>
      <div
        v-if="isMenu"
        class="l-page-side-overlay"
        @click="useSettingStore().toggleMenu()"
      />
      <AlertBar />
      <slot />
    </div>
    <AlertBar />
    <PageLoadingFull :is-loading="isLoading" />
  </div>
</template>

<script setup lang="ts">
const isMenu = computed(() => useSettingStore().isMenu)
const isLoading = computed(() => useSettingStore().isLoading)
</script>

<style lang="scss" scoped>
.l-page {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  @include media-sp {
    background-color: #fff;
  }

  &-side {
    position: fixed;
    right: 0;
    top: 0;
    width: $sidebar-width;
    overflow-y: scroll;
    z-index: calc($z-index-base + $z-index-base + 10);
    height: 100%;
    @include scrollbar();
    transform: translateX(0%);
    transition: transform 0.3s ease;
    box-shadow: -7px 0px 20px 0px rgba(0, 0, 0, 0.1);
    @include media-pc {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $sidemenu-bg-color;
    }

    @include media-sp {
      position: fixed;
      z-index: $sp-sidemenu-z-index;
      top: 0;
      right: 0;
    }

    &:not(.menu) {
      @include media-sp-tb {
        transform: translateX(150%);
      }
    }

    &-overlay {
      @include media-sp {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: $sp-sidemenu-overlay-z-index;
      }
    }
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    flex-shrink: 10;
    @include scrollbar();
  }
}
</style>
